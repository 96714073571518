import {LitElement, html, css, customElement} from 'lit-element';

import "weightless/card"
import "weightless/text"
import "weightless/button"
import "weightless/icon"

const styles = require('./shared.lit.scss').default;

const {version} = require('package.json');

@customElement('app-root')
export class App extends LitElement { 
  
  static get styles() {
    return [styles, css`
    wl-button[launch] {
      float: right;
    }
    tags:before {
      content:'think: ';
      font-weight: 600;
      font-style: initial;
    }
    tags {
      display: block;
      margin-top: 10px;
      font-style:italic;
    }
    `];
  }

  link(url:string) {
    return () => window.location.href = '/'+url;
  }

  render() {
    return html`
    <h1>
    <wl-icon title-icon>graphic_eq</wl-icon>
    Audio tools <span super>${version}</span>
    <h4>Maybe it helps..</h4>
      <wl-text>
        This app contains a small set of browser-based audio tools.
        Typically, the were developed to solve issues i personally faced.
        Praise and hate go here: sijakret@gmail.com
      </wl-text>
      <br>

    <wl-card>
      <wl-text>
        <h2>Google Drive Player</h2>
        Google drive is cool.
        I use it to mirror my projects and share previews or finals.
        However, the built-in audio player is not cool.
        This player allows you to create a simple player for
        public folders that you can share with your friends or foes.
        <tags>drive, mp3, playlist, work in progress</tags>
        <wl-button launch @click=${this.link('drive-audio')}>launch</wl-button>
      </wl-text>
    </wl-card>
    
    <wl-card>
      <wl-text>
        <h2>PCM Dropout removal</h2>
        Interpolate dropped (zero) samples in audio files.
        Supports batch processing.
        <tags>wav, aiff, restoration, crackle, dropouts</tags>
        <wl-button launch @click=${this.link('dropouts')}>launch</wl-button>
      </wl-text>
    </wl-card>`;
  }

};